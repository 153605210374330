import { ITagListItem } from '@interfaces';
import { EuiTableActionsColumnType } from '@elastic/eui';
import { ResourceTable, ResourceTableProps } from '@components/table/ResourceTable';
import { Menu } from '@mantine/core';

import TagTableColumns from './TagTableColumns';
import TagTableExpandedRow from './TagTableExpandedRow';
import { EditAction } from '@components/table/actions/EditAction';
import { useIdentity } from '@components/data/Identity.context';
import { useFeature } from 'flagged';
import { IconLink } from '@tabler/icons-react';
import { DeleteAction } from '@components/table/actions/DeleteAction';
import { EquipmentLookupModal } from '@components/ui/modals/EquipmentSelectModal';
import { useDisclosure } from '@mantine/hooks';
import { useState } from 'react';
import { useMergeTag } from '@components/hooks/useMergeTag';
import { ArchiveAction } from '@components/table/actions/ArchiveAction';
import { ActionMenu } from '@components/table/actions/ActionMenu';
import { UnarchiveAction } from '@components/table/actions/UnarchiveAction';

export const TagTable = (props: ResourceTableProps<ITagListItem>) => {
  const { identity } = useIdentity();
  const hasVMSSync = useFeature('has_vms_sync');
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedTag, setSelectedTag] = useState<ITagListItem>(null);
  const { mergeTag } = useMergeTag();

  const actions = [
    {
      name: '',
      width: '40',
      actions: [
        {
          render: (record) => <ActionMenu id={record.id}>
            <EditAction />

            { !record.integration_platform && hasVMSSync && <>
              <Menu.Item
                color="blue"
                onClick={() => {
                  setSelectedTag(record);
                  open();
                }}
                leftSection={<IconLink size={18} stroke={1.5} />}
                closeMenuOnClick={true}
              >Merge</Menu.Item>
            </>}

            <Menu.Divider />

            { !record.archived_at && <ArchiveAction disabled={!identity.permissions.includes('tag.delete')} />}
            { record.archived_at && <UnarchiveAction disabled={!identity.permissions.includes('tag.delete')} />}

            <DeleteAction target={record.name}
                          disabled={record.account_name !== '6L Inc' && (!!record.refunds_count || !!record.feedback_requests_count || !!record.help_requests_count) && identity.permissions.includes('tag.delete') && !record.integration_platform} />
          </ActionMenu>
        }
      ]
    },
  ] as EuiTableActionsColumnType<ITagListItem>[];

  const mergeModalTitle = selectedTag ? <>Merge <b>{ selectedTag.name } (ID: {selectedTag.asset_id === selectedTag.identifier ? selectedTag.asset_id : selectedTag.identifier})</b> with &hellip;</> : '';

  const handleMerge = (tag: ITagListItem) => {
    mergeTag(selectedTag.id, tag.id);
  };

  return <>
    <EquipmentLookupModal title={mergeModalTitle}
                          opened={opened}
                          close={() => {
                            setSelectedTag(null);
                            close();
                          }}
                          onChange={handleMerge} />

    <ResourceTable<ITagListItem>
      resource="tag"
      columns={[...TagTableColumns, ...actions]}
      onRenderExpanded={TagTableExpandedRow}
      {...props}
    />
  </>;
}
